import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { colors, breakpoints } from '../style'

const CoverWrapper = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  background-color: ${props => props.bg || colors.dark};

  @media screen and ${breakpoints.medium} {
    padding-top: ${props => (props?.isVideo ? '56.25%' : '100%')};
  }

  > figure,
  video,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${props =>
    props?.heroOverlay &&
    `
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          transparent 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
    `}
`

export default function Cover(props) {
  return <CoverWrapper {...props} />
}

export const query = graphql`
  fragment CoverImage on ImageSharp {
    fluid(maxWidth: 1520, quality: 80) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
`
